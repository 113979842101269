<template>
  <b-card
    class="stockoutbounditem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="stockoutbounditemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库出库单编号"
            label-for="stockout_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stockout_no"
              size="sm"
              v-model="stockoutbounditem.stockout_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库单明细ID"
            label-for="outbounditem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbounditem_id"
              size="sm"
              v-model="stockoutbounditem.outbounditem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库单ID"
            label-for="outbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbound_id"
              size="sm"
              v-model="stockoutbounditem.outbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出库单编号"
            label-for="outbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbound_no"
              size="sm"
              v-model="stockoutbounditem.outbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="stockoutbounditem.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单产品明细ID"
            label-for="orderitem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="orderitem_id"
              size="sm"
              v-model="stockoutbounditem.orderitem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方仓库ID"
            label-for="ourwarehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="ourwarehouse_id"
              size="sm"
              v-model="stockoutbounditem.ourwarehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方仓库名称"
            label-for="ourwarehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="ourwarehouse_name"
              size="sm"
              v-model="stockoutbounditem.ourwarehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方仓库联系人"
            label-for="our_contact"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="our_contact"
              size="sm"
              v-model="stockoutbounditem.our_contact"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方仓库联系电话"
            label-for="our_mobile"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="our_mobile"
              size="sm"
              v-model="stockoutbounditem.our_mobile"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方仓库地址"
            label-for="our_location"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="our_location"
              size="sm"
              v-model="stockoutbounditem.our_location"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方仓库ID"
            label-for="otherwarehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="otherwarehouse_id"
              size="sm"
              v-model="stockoutbounditem.otherwarehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方仓库名称"
            label-for="otherwarehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="otherwarehouse_name"
              size="sm"
              v-model="stockoutbounditem.otherwarehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方仓库联系人"
            label-for="other_contact"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="other_contact"
              size="sm"
              v-model="stockoutbounditem.other_contact"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方仓库联系电话"
            label-for="other_mobile"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="other_mobile"
              size="sm"
              v-model="stockoutbounditem.other_mobile"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方仓库地址"
            label-for="other_location"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="other_location"
              size="sm"
              v-model="stockoutbounditem.other_location"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="store_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_id"
              size="sm"
              v-model="stockoutbounditem.store_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺名称"
            label-for="store_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_name"
              size="sm"
              v-model="stockoutbounditem.store_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出货时间"
            label-for="delivery_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="delivery_time"
              size="sm"
              v-model="stockoutbounditem.delivery_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="预计到货时间"
            label-for="arrival_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="arrival_time"
              size="sm"
              v-model="stockoutbounditem.arrival_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="stockoutbounditem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="stockoutbounditem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="stockoutbounditem.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="batch_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="batch_no"
              size="sm"
              v-model="stockoutbounditem.batch_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="stockoutbounditem.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="有效日期"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="stockoutbounditem.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类ID"
            label-for="category_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_id"
              size="sm"
              v-model="stockoutbounditem.category_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类名称"
            label-for="category_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_name"
              size="sm"
              v-model="stockoutbounditem.category_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌ID"
            label-for="brand_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_id"
              size="sm"
              v-model="stockoutbounditem.brand_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="品牌名称"
            label-for="brand_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="brand_name"
              size="sm"
              v-model="stockoutbounditem.brand_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态 instock_status"
            label-for="warestatus"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warestatus"
              size="sm"
              v-model="stockoutbounditem.warestatus"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="计划出库数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="stockoutbounditem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际出库数量"
            label-for="trueqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="trueqty"
              size="sm"
              v-model="stockoutbounditem.trueqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="stockoutbounditem.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="stockoutbounditem.creator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import stockoutbounditemStore from './stockoutbounditemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      stockoutbounditem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('stockoutbounditem')) store.registerModule('stockoutbounditem', stockoutbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockoutbounditem')) store.unregisterModule('stockoutbounditem')
    })

    const edit = function() {
      store.dispatch('stockoutbounditem/edit', {id: this.id}).then(res => {
        this.stockoutbounditem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('stockoutbounditem/view', {id: this.id}).then(res => {
        this.stockoutbounditem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('stockoutbounditem/save', this.stockoutbounditem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-stockoutbounditem-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>